<template>
  <div class="settings_manufacturing_tests_pipeline" v-if="pipelineid">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='information'" v-on:click="tab='information'">Information</b-nav-item>
      <b-nav-item :active="tab=='workers'" v-on:click="tab='workers'">Workers</b-nav-item>
    </b-nav>

    <div v-if="tab == 'information' && pipeline != null">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Name:">
            <b-form-input v-model="pipeline.name"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-12">
          <b-form-group label="Id:">
            <b-form-input v-model="pipeline.id" disabled></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>

    <div v-if="tab == 'workers'">

      <div class="row mb-3">
        <div class="col">
          <b-button-group>
            <b-button variant="outline-primary" v-on:click="workers"><b-icon-arrow-repeat></b-icon-arrow-repeat></b-button>
          </b-button-group>
        </div>
        <div class="col-lg-4 col-md-12">
          <b-form v-on:submit.prevent="workers">
            <b-input-group>
              <b-input type="text" ref="search" placeholder="Search..." v-model="search" disabled/>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="outline-primary" v-on:click="workers" disabled><b-icon-search></b-icon-search></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-table id="main-table" striped hover :items="items" :fields="fields" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(name)="row">
            <b-icon-check-circle-fill variant="success" v-if="row.item.startup > row.item.shutdown"></b-icon-check-circle-fill>
            <b-icon-x-circle-fill variant="danger" v-if="row.item.startup < row.item.shutdown"></b-icon-x-circle-fill>
            <b-icon-question-circle-fill variant="warning" v-if="row.item.startup == row.item.shutdown"></b-icon-question-circle-fill>
            <b-link class="link text-primary pl-2" :to="'/settings/manufacturing/tests/' + pipeline.id + '/worker/' + row.item.id">{{ row.item.name }}</b-link>
          </template>
          <template #cell(lastcheckin)="row">
            {{ row.item.lastcheckin | moment('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template #cell(time)="row">
            {{ row.item.time.toFixed(2) }} sec
          </template>
          <template #cell(speed)="row">
            {{ row.item.speed.toFixed(2) }} b/s
          </template>
          <template #cell(size)="row">
            {{ (row.item.size / 1024).toFixed(2) }} kb
          </template>
          <template #cell(status)="row">
            <b-icon-hand-thumbs-up variant="success"></b-icon-hand-thumbs-up> <span class="text-success">{{ row.item.processed }}</span> / <span class="text-danger">{{ row.item.errors }}</span> <b-icon-hand-thumbs-down variant="danger"></b-icon-hand-thumbs-down>
          </template>
          <template #cell(logs)="row">
            <b-link :to="'/settings/manufacturing/tests/' + pipelineid + '/worker/' + row.item.id + '?tab=logs'"><b-badge pill variant="primary" class="px-3">logs</b-badge></b-link>
          </template>
          </b-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>
          Showing {{ ((currentPage - 1) * currentPageSize) + 1 }} to {{ activeRows() }} of {{ totalItems }} rows 
          <b-dropdown variant="outline-primary" :text="(currentPageSize).toString()" v-model="currentPageSize">
            <b-dropdown-item v-on:click="currentPageSize=10">10</b-dropdown-item>
            <b-dropdown-item v-on:click="currentPageSize=20">20</b-dropdown-item>
            <b-dropdown-item v-on:click="currentPageSize=50">50</b-dropdown-item>
            <b-dropdown-item v-on:click="currentPageSize=100">100</b-dropdown-item>
          </b-dropdown>
          rows per page</span>
        </div>
        <div class="col">
          <b-pagination align="right" v-if="!isBusy" v-model="currentPage" :per-page="currentPageSize" :total-rows="totalItems" aria-controls="main-table"></b-pagination>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { testsService } from '@/services'
import { tablePagination } from '@/mixins/table-pagination'

export default {
  name: 'Pipeline',
  mixins: [
    tablePagination
  ],
  data () {
    return {
      workername: '',
      tab: 'information',
      pipeline: null,
      worker: null,
      fields: ['name','site','owner','lastcheckin',{key: 'status', label: 'Uploads'}, {key: 'logs', label: ''}],
      isBusy: false,
      search: '',
      breadcrumb: [
        { text: 'Home', to: '/' },
        { text: 'Settings', to: '/settings' },
        { text: 'Manufacturing', to: '/settings/manufacturing' },
        { text: 'Tests', to: '/settings/manufacturing/tests' },
        { text: '', active: true },
      ]
    }
  },
  computed: {
    pipelineid() {
      return this.$route.params.pipelineid
    },
  },
  watch: {
    currentPage() {
      this.workerlogs()
    },
    currentPageSize() {
      this.workerlogs()
    }
  },
  methods: {
    async loaddata() {

      this.pipeline = await testsService.getById(this.pipelineid)
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })

      this.breadcrumb[4].text = this.pipeline.name;

      await this.workers()
      
    },
    async workers() {

      this.isBusy = true

      let workers_result = await testsService.getAllWorkers(this.pipelineid, this.search, this.currentPageSize, this.currentPage)
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })

      this.totalItems = workers_result.count
      this.items = workers_result.data

      this.isBusy = false

    },
  },
  async mounted() {
    if(this.$route.query.tab) { this.tab = this.$route.query.tab }
    this.loaddata()
  }
}
</script>
